import withBulkEdit from '../../helpers/withBulkEdit';

const BulkEditCm360LinkCell = () => {
    return (
        <div
            style={{
                backgroundColor: 'lightgray',
                borderRadius: '8px',
                padding: '10px',
                textAlign: 'center',
            }}
        >
            We will attempt to link to TradeDesk creatives by name for the above placements.
            <div style={{ fontSize: '12px' }}>(CM360 placement name = TTD creative name)</div>
        </div>
    );
};

export default withBulkEdit(BulkEditCm360LinkCell);
