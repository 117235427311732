import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useState } from 'react';
import { GOODWAY_RED, GOODWAY_SAND_400 } from '../../constants/colors';
import { GRAIN_TO_ID, ID_TO_GRAIN } from '../../constants/dimensions';
import { platformList } from '../../constants/platformList';
import { PlatformLogo } from '../atoms/PlatformLogo';
import Confirm from './Confirm';
import GrainSelectorModal from './GrainSelectorModal';
const NoLinking = (placement_name, platform, setOpen) => {
    return (
        <>
            <div onClick={() => setOpen(true)} style={{ display: 'flex', alignItems: 'center' }}>
                <PlatformLogo
                    platform={{ name: placement_name, logo: platformList[platform].logo }}
                    placement={'left'}
                />
                <LinkOffIcon style={{ fill: GOODWAY_SAND_400 }} />
                <NotInterestedIcon style={{ fill: GOODWAY_RED }} />
            </div>
        </>
    );
};
const Linking = (placement_name, platform, linkedPlatform, deleteRule) => {
    return (
        <div onClick={deleteRule} style={{ display: 'flex', alignItems: 'center' }}>
            <PlatformLogo
                platform={{ name: placement_name, logo: platformList[platform].logo }}
                placement={'left'}
            />
            <LinkIcon style={{ fill: GOODWAY_SAND_400 }} />
            <PlatformLogo
                platform={{
                    name: linkedPlatform?.grain
                        ? `${ID_TO_GRAIN[linkedPlatform?.grain]} (${linkedPlatform?.name})`
                        : linkedPlatform?.name,
                    logo: platformList[linkedPlatform?.platform]?.logo,
                }}
                placement={'left'}
            />
        </div>
    );
};
const CM360LinkCell = ({ row, deleteRule, addRule, accountMap }) => {
    if (row.alias) {
        console.log('row', row);
    }
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [open, setOpen] = useState(false);

    if (row.platform !== 'dcm') {
        return <span style={{ color: 'white' }}>N/A</span>;
    }
    const deleteRuleLocal = () => {
        setConfirmDelete(true);
    };
    const onConfirm = (grain, dimension) => {
        addRule({
            type: 'cm360Mapping',
            config: {
                target_id: row.grainID,
                source_id: grain.value,
                source_level: GRAIN_TO_ID[dimension],
                source_platform: grain.platform,
                source_name: grain.label,
            },
        });
    };
    const platform = platformList[row.platform];
    return platform?.logo ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid lightgray',
                borderRadius: '5px',
                padding: '5px',
                transition: 'background-color 0.3s, cursor 0.3s',
            }}
            onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = '#f0f0f0';
                e.currentTarget.style.cursor = 'pointer';
            }}
            onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.cursor = 'default';
            }}
        >
            <GrainSelectorModal
                title={`Select Dimension to Link`}
                row={row}
                accountMap={accountMap}
                open={open}
                setOpen={setOpen}
                onConfirm={onConfirm}
            />
            <Confirm
                title={`Remove CM360 Linking?`}
                message={`Removing the link between this CM360 Placement and ${row?.cm360_linking?.source_information?.platform} ${row?.cm360_linking?.source_platform_grain}. Do you want to proceed? You will have the opportunity to re-link after if needed.`}
                onConfirm={() => {
                    deleteRule(
                        row?.cm360_linking?.config?.source_id,
                        row?.cm360_linking?.config?.target_id,
                    );
                }}
                open={confirmDelete}
                setOpen={setConfirmDelete}
                color='error'
            />
            {!!row.cm360_linking
                ? Linking(
                      row?.alias?.config?.alias || row.name,
                      row.platform,
                      row.cm360_linking.source_information,
                      deleteRuleLocal,
                  )
                : NoLinking(row?.alias?.config?.alias || row.name, row.platform, setOpen)}
        </div>
    ) : (
        row.platform
    );
};

export default CM360LinkCell;
