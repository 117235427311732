import { useLazyQuery } from '@apollo/client';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { UI_FRIENDLY_DIMENSIONS } from '../../constants/dimensions';
import { platformList } from '../../constants/platformList';
import { formatAccountMap } from '../../helpers/utils';
import { GET_GRAIN_HIERARCHY } from '../../queries/grainHierarchy';
import { PlatformLogo } from '../atoms/PlatformLogo';
import StyledAutocomplete from '../atoms/StyledAutocomplete';
import StyledChip from '../atoms/StyledChip';
import StyledModal from '../atoms/StyledModal';
const GrainSelectorModal = ({ row, title, onConfirm, open, setOpen, accountMap }) => {
    const abortControllerRef = useRef({});
    const [dimOptions] = useState(Object.keys(UI_FRIENDLY_DIMENSIONS));
    const [dimensions, setDimensions] = useState({});
    const [selectedDimension, setSelectedDimension] = useState('campaign');
    const [grains, setGrains] = useState([]);
    const [selectedGrain, setSelectedGrain] = useState(null);
    const [refreshGrainHierarchy] = useLazyQuery(GET_GRAIN_HIERARCHY);
    useEffect(() => {
        setGrains(dimensions[selectedDimension] || []);
    }, [dimensions, selectedDimension]);
    useEffect(() => {
        if (abortControllerRef.current) {
            Object.values(abortControllerRef.current).forEach(controller => controller.abort());
        }
        const abortController = new window.AbortController();
        abortControllerRef.current = abortController;
        refreshGrainHierarchy({
            variables: {
                accountMap: formatAccountMap(accountMap),
                grainTypes: [selectedDimension],
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(({ data }) => {
            setDimensions(prev => ({
                ...prev,
                [selectedDimension]: data?.getGrainHierarchy,
            }));
        });
    }, [accountMap, selectedDimension]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        onConfirm(selectedGrain, selectedDimension);
        setOpen(false);
    };

    const handleGrainChange = (_event, newValue) => {
        setSelectedGrain(newValue);
    };

    const handleDimensionChange = (_event, newValue) => {
        setSelectedDimension(newValue);
    };
    return (
        <StyledModal open={open} onClose={handleClose} header={`${title}`}>
            <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={3}
                sx={{ height: '100%' }}
            >
                <Grid item xs={12}>
                    <Typography variant='h6' align='center' sx={{ color: 'white' }}>
                        {row.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LinkIcon style={{ fontSize: 100, color: 'white' }} />
                </Grid>
                <Grid item xs={12} sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                        <StyledAutocomplete
                            id='dimension-select'
                            label='Select Dimension'
                            value={selectedDimension}
                            onChange={handleDimensionChange}
                            options={dimOptions}
                            clearOnEscape={false}
                            isOptionEqualToValue={(option, value) => option === value}
                            disableClearable
                            disableCloseOnSelect={false}
                            clearOnBlur={false}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        shrink: true,
                                        mt: '-3px',
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ width: '100%' }}>
                    <FormControl fullWidth>
                        <StyledAutocomplete
                            id='grain-select'
                            label='Select Grain'
                            groupBy={option => platformList[option?.platform]?.name || ''}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Box display='flex' alignItems='center'>
                                        <Box mr={1}>
                                            <PlatformLogo
                                                platform={platformList[option.platform]}
                                                placement='top'
                                            />
                                        </Box>
                                        <Typography>{option.label}</Typography>
                                    </Box>
                                </li>
                            )}
                            value={selectedGrain}
                            onChange={handleGrainChange}
                            options={grains
                                .map(x => ({
                                    label: x.name,
                                    value: x.platform_grain_id,
                                    platform: x.platform,
                                }))
                                .sort((a, b) => (a.platform > b.platform ? 1 : -1))}
                            clearOnEscape={false}
                            isOptionEqualToValue={(option, value) => option === value}
                            disableClearable
                            disableCloseOnSelect={false}
                            clearOnBlur={false}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    placeholder={`Select ${selectedDimension || 'Dimension'}`}
                                    variant='outlined'
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        shrink: true,
                                        mt: '-3px',
                                    }}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <StyledChip
                                        key={option}
                                        label={
                                            <Typography
                                                sx={{ whiteSpace: 'normal', pt: '5px', pb: '5px' }}
                                            >
                                                {option}
                                            </Typography>
                                        }
                                        sx={{ height: '100%', textTransform: 'none' }}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleConfirm}
                        disabled={!selectedGrain || selectedGrain.length === 0}
                    >
                        Link
                    </Button>
                </Grid>
            </Grid>
        </StyledModal>
    );
};

export default GrainSelectorModal;
