import { useQuery } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Switch, Typography } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { GOODWAY_YELLOW } from '../../constants/colors';
import { formatDimensionName } from '../../helpers/dataset';
import { deleteCM360Rule, updateRules } from '../../helpers/rules';
import { formatAccountMap } from '../../helpers/utils';
import { filterNameOrAlias, getCreativeUrl, updateTagValue } from '../../helpers/utils';
import { GET_GRAIN_HIERARCHY } from '../../queries/grainHierarchy';
import AutocompleteChannelCell from '../atoms/AutocompleteChannelCell';
import CreativeUrlTextCell from '../atoms/CreativeUrlTextCell';
import FileUploadCell from '../atoms/FileUploadCell';
import { StyledAccordionDetails } from '../atoms/StyledAccordionDetails';
import TextInputTagCell from '../atoms/TextInputTagCell';
import AliasCell from '../molecules/AliasCell';
import BulkEditBoolean from '../molecules/BulkEditBoolean';
import BulkEditCM360LinkCell from '../molecules/BulkEditCM360LinkCell';
import BulkEditChannelAutocompleteCell from '../molecules/BulkEditChannelAutocompleteCell';
import BulkEditTagField from '../molecules/BulkEditTagField';
import BulkEditTextField from '../molecules/BulkEditTextField';
import BulkFileUpload from '../molecules/BulkFileUpload';
import CM360LinkCell from '../molecules/CM360LinkCell';
import PlatformCell from '../molecules/PlatformCell';
import PlatformMultiSelect from '../molecules/PlatformMultiSelect';
import GrainTable from './GrainTable';
import AliasColumn from './datagrid/AliasColumn';

const ALLOWED_GRAINS = {
    account: {
        defaultFieldName: 'account_name_alias',
        idColumn: 'account_id',
    },
    campaign_group: {
        defaultFieldName: 'campaign_group_name_alias',
        idColumn: 'campaign_group_id',
    },
    campaign: {
        defaultFieldName: 'campaign_name_alias',
        idColumn: 'campaign_id',
    },
    adgroup: {
        defaultFieldName: 'adgroup_name_alias',
        idColumn: 'adgroup_id',
    },
    placement: {
        defaultFieldName: 'placement_name_alias',
        idColumn: 'placement_id',
    },
    line_item: {
        defaultFieldName: 'line_item_name_alias',
        idColumn: 'line_item_id',
    },
    ad: {
        defaultFieldName: 'ad_name_alias',
        idColumn: 'ad_id',
    },
    creative: {
        defaultFieldName: 'creative_name_alias',
        idColumn: 'creative_id',
    },
    keyword: {
        defaultFieldName: 'keyword_alias',
        idColumn: 'keyword_id',
    },
};

const AliasingAccordion = ({
    onUpload,
    datasetMeta,
    dimensions,
    setDimensions,
    assets = [],
    loading,
    accountMap,
    currentDimension,
    setCurrentDimension,
    setDatasetRules,
}) => {
    const {
        loading: creativeGrainLoading,
        error: creativeGrainError,
        data: creativeGrain,
    } = useQuery(GET_GRAIN_HIERARCHY, {
        variables: {
            grainTypes: ['creative'],
            accountMap: formatAccountMap(accountMap),
        },
    });
    const deleteRowRule = (source_id, target_id) => {
        setDatasetRules(oldDatasetRules => {
            const updatedRules = [...oldDatasetRules];
            deleteCM360Rule(updatedRules, source_id, target_id);
            return updatedRules;
        });
    };
    const addRule = newRule => {
        setDatasetRules(oldDatasetRules => {
            const updatedRules = [...oldDatasetRules];
            updateRules(updatedRules, newRule, false);
            return updatedRules;
        });
    };
    const applyRowChange = (_row, newRule, _changedIndex, deleteRule) => {
        setDatasetRules(oldDatasetRules => {
            const updatedRules = [...oldDatasetRules];
            updateRules(updatedRules, newRule, deleteRule);

            return updatedRules;
        });
    };

    const [dimensionPlatforms, setDimensionPlatforms] = useState([]);
    const defaultFieldName = ALLOWED_GRAINS[currentDimension].defaultFieldName;
    const dimensionId = ALLOWED_GRAINS[currentDimension].idColumn;

    useEffect(
        () =>
            setDimensionPlatforms(() => dimensions?.platform?.map(platform => platform.name) || []),
        [dimensions],
    );

    const getRowIndex = row =>
        dimensions &&
        dimensions[currentDimension] &&
        dimensions[currentDimension].findIndex(({ id }) => id === row.id);

    const CONDITIONAL_COLUMNS = [
        {
            field: 'Campaign',
            hideable: true,
            valueGetter: ({ row }) => {
                const campaignID = row.path && row.path[0];

                if (campaignID) {
                    const campaign = dimensions?.campaign?.find(
                        ({ grainID }) => grainID === campaignID,
                    );

                    return campaign?.alias?.config?.alias || campaign?.name;
                }
            },
            headerName: 'Campaign',
            grains: ['adgroup'],
            minWidth: 225,
        },
    ];
    const attemptLinkToTTDByName = row => {
        const creative = creativeGrain?.getGrainHierarchy?.find(
            creative => creative.platform === 'ttd' && creative.name === row.name,
        );
        const isRowLinked = !!row.cm360_linking;
        if (creative && !isRowLinked) {
            return {
                type: 'cm360Mapping',
                config: {
                    target_id: row.grainID,
                    source_id: creative.platform_grain_id,
                    source_level: 'creative_id',
                    source_platform: 'ttd',
                    source_name: creative.name,
                },
            };
        }
    };
    // add grains key to limit when these tag options show up based on grain.
    const RULE_COLUMNS = [
        {
            id: 'creative',
            name: 'Creative Asset',
            Cell: FileUploadCell,
            grains: ['creative', 'ad'],
            valueGetter: ({ row }) => row.tags?.creative?.url,
            showEditActions: false,
            EditCell: props => (
                <BulkFileUpload bucket={datasetMeta.assetBucket} onUpload={onUpload} {...props} />
            ),
        },
        {
            id: 'placement_link',
            name: 'CM360 Link',
            EditCell: props =>
                !creativeGrainLoading &&
                !creativeGrainError && (
                    <BulkEditCM360LinkCell {...props} updateRow={attemptLinkToTTDByName} />
                ),
            Cell: ({ row }) => (
                <CM360LinkCell
                    row={row}
                    accountMap={accountMap}
                    applyRowChange={applyRowChange}
                    deleteRule={deleteRowRule}
                    addRule={addRule}
                />
            ),
            grains: ['placement'],
            valueGetter: ({ row }) => !!row.cm360_linking,
        },
        {
            id: 'creativeUrl',
            tagId: 'creative',
            name: 'Creative URL',
            Cell: CreativeUrlTextCell,
            EditCell: props => (
                <BulkEditTextField
                    updateRow={(row, value) => ({
                        type: 'tag',
                        config: {
                            tag_type: 'alias',
                            field: dimensionId,
                            key: row.grainID,
                            grain_id: row.grainID,
                            bucket: 'creative',
                            grain: `${currentDimension}_id`,
                            tag_value: value,
                            reference: false,
                        },
                    })}
                    {...props}
                />
            ),
            grains: ['creative', 'ad'],
            valueGetter: ({ row }) => getCreativeUrl(row.tags),
        },
        {
            id: 'creativeSize',
            name: 'Creative Size',
            grains: ['creative'],
            Cell: AliasCell,
            EditCell: props => (
                <BulkEditTextField
                    updateRow={(row, value) => ({
                        type: 'alias',
                        deleteRule: !value,
                        config: {
                            field: 'creative_size_id',
                            default_field: 'creative_size_alias',
                            key: row.grainID,
                            alias: value,
                        },
                    })}
                    {...props}
                />
            ),
            getPropsFromRow: (row = {}) => ({
                alias: row.sizeAlias?.config?.alias,
                name: row.creativeSize,
                field: 'creative_size_id',
                default_field: 'creative_size_alias',
                key_field: row.grainID,
                labelText: 'Original Size: ',
            }),
            valueGetter: ({ row }) => row.sizeAlias?.config?.alias || row.creativeSize,
        },
        {
            id: 'channel',
            tagId: 'channel',
            name: 'Channel',
            Cell: AutocompleteChannelCell,
            EditCell: props => (
                <BulkEditChannelAutocompleteCell
                    updateRow={(row, value) =>
                        updateTagValue(value, row, 'channel', currentDimension)
                    }
                    {...props}
                />
            ),
            grains: Object.keys(ALLOWED_GRAINS),
            isValueInherited: true,
            valueGetter: ({ row }) =>
                row.tags?.channel?.config?.tag_value ||
                row.inheritedRules?.tags?.channel?.config?.tag_value ||
                '',
        },
        {
            id: 'tactic',
            tagId: 'tactic',
            name: 'Tactic',
            Cell: TextInputTagCell,
            EditCell: BulkEditTagField,
            grains: Object.keys(ALLOWED_GRAINS),
            isValueInherited: true,
            valueGetter: ({ row }) =>
                row.tags?.tactic?.config?.tag_value ||
                row.inheritedRules?.tags?.tactic?.config?.tag_value ||
                '',
        },
        {
            id: 'audience',
            tagId: 'audience',
            name: 'Audience',
            Cell: TextInputTagCell,
            EditCell: BulkEditTagField,
            grains: Object.keys(ALLOWED_GRAINS),
            isValueInherited: true,
            valueGetter: ({ row }) =>
                row.tags?.audience?.config?.tag_value ||
                row.inheritedRules?.tags?.audience?.config?.tag_value ||
                '',
        },
        {
            id: 'market',
            tagId: 'market',
            name: 'Market',
            Cell: TextInputTagCell,
            EditCell: BulkEditTagField,
            grains: Object.keys(ALLOWED_GRAINS),
            isValueInherited: true,
            valueGetter: ({ row }) =>
                row.tags?.market?.config?.tag_value ||
                row.inheritedRules?.tags?.market?.config?.tag_value ||
                '',
        },
    ];

    const columns = useMemo(
        () => [
            {
                field: 'platform',
                hideable: true,
                headerName: 'Platform',
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringOperators()
                    .filter(operator => operator.value === 'isAnyOf')
                    .map(operator => ({
                        ...operator,
                        InputComponent: props => (
                            <PlatformMultiSelect {...props} platforms={dimensionPlatforms} />
                        ),
                    })),
                renderCell: ({ row }) => <PlatformCell row={row} />,
            },
            {
                field: 'include',
                hideable: true,
                headerName: `Include`,
                width: 125,
                type: 'boolean',
                valueGetter: ({ row }) => !row.omit,
                renderCell: ({ row }) => (
                    <Switch
                        checked={!row.omit}
                        onChange={({ target }) =>
                            applyRowChange(
                                row,
                                {
                                    type: 'grainFilter',
                                    config: {
                                        field: `${currentDimension}_id`,
                                        key: row.grainID,
                                        type: 'grainFilter',
                                    },
                                },
                                getRowIndex(row),
                                target.checked,
                            )
                        }
                    />
                ),
                EditCell: props => (
                    <BulkEditBoolean
                        {...props}
                        updateRow={(row, value) => ({
                            deleteRule: value,
                            type: 'grainFilter',
                            config: {
                                field: `${currentDimension}_id`,
                                key: row.grainID,
                                type: 'grainFilter',
                            },
                        })}
                    />
                ),
            },
            {
                field: 'grainID',
                hideable: true,
                headerName: `${formatDimensionName(currentDimension)} ID`,
                width: 100,
            },
            {
                field: 'name',
                hideable: true,
                headerName: `${formatDimensionName(currentDimension)} (Click Cell to Alias)`,
                flex: 1,
                minWidth: 350,
                valueGetter: ({ row }) => row.alias?.config?.alias || row.name,
                getApplyQuickFilterFn:
                    searchTerm =>
                    ({ row }) =>
                        filterNameOrAlias(row.name, row.alias?.config?.alias, searchTerm),
                renderCell: ({ row }) => (
                    <AliasCell
                        row={row}
                        applyRowChange={applyRowChange}
                        id={row.id}
                        alias={row.alias?.config?.alias}
                        aliasId={row.alias?.config?.id}
                        name={row.name}
                        field={dimensionId}
                        default_field={defaultFieldName}
                        key_field={row.grainID}
                        default_row_name={row.name}
                        rowIndex={getRowIndex(row)}
                    />
                ),
                EditCell: props => (
                    <BulkEditTextField
                        updateRow={(row, value) => ({
                            type: 'alias',
                            config: {
                                field: dimensionId,
                                default_field: defaultFieldName,
                                key: row.grainID,
                                alias: value,
                            },
                        })}
                        label={`${formatDimensionName(currentDimension)} Alias`}
                        {...props}
                    />
                ),
            },
            ...CONDITIONAL_COLUMNS.filter(column => column.grains?.includes(currentDimension)),
            AliasColumn(),
            ...RULE_COLUMNS.filter(column => column.grains?.includes(currentDimension)).map(
                ({
                    tagId,
                    id,
                    name,
                    Cell,
                    getPropsFromRow = () => {},
                    valueGetter,
                    EditCell,
                    showEditActions,
                }) => ({
                    field: id,
                    hideable: true,
                    headerName: name,
                    width: 200,
                    valueGetter: valueGetter && (({ row }) => valueGetter({ row })),
                    currentDimension,
                    tagId,
                    renderCell: ({ row }) => (
                        <Cell
                            key={id}
                            assets={assets}
                            onUpload={onUpload}
                            row={row}
                            id={id}
                            tagId={tagId}
                            datasetMeta={datasetMeta}
                            applyRowChange={applyRowChange}
                            rowIndex={getRowIndex(row)}
                            grainType={currentDimension}
                            {...getPropsFromRow(row)}
                        />
                    ),
                    EditCell,
                    showEditActions,
                }),
            ),
        ],
        [
            assets,
            currentDimension,
            dimensions,
            dimensionPlatforms,
            RULE_COLUMNS,
            CONDITIONAL_COLUMNS,
        ],
    );

    return (
        <Accordion
            sx={{
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
                backgroundColor: '#272B49',
                marginTop: 3,
                marginBottom: 1,
                borderRadius: '10px',
                boxShadow: 'none',
            }}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon sx={{ pointerEvents: 'auto', color: GOODWAY_YELLOW }} />
                }
            >
                <Typography
                    variant={'h5'}
                    style={{ color: GOODWAY_YELLOW, fontSize: 19, fontWeight: 300 }}
                >
                    Reporting Dimensions
                </Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
                <GrainTable
                    setDatasetRules={setDatasetRules}
                    datasetMeta={datasetMeta}
                    assets={assets}
                    accountMap={accountMap}
                    loading={loading}
                    setDimensions={setDimensions}
                    dimensions={dimensions}
                    allowedGrains={Object.keys(ALLOWED_GRAINS)}
                    currentDimension={currentDimension}
                    setCurrentDimension={setCurrentDimension}
                    columns={columns}
                    initialState={{
                        pinnedColumns: {
                            left: [
                                GRID_CHECKBOX_SELECTION_COL_DEF.field,
                                'platform',
                                'include',
                                'grainID',
                                'name',
                            ],
                        },
                        columns: {
                            columnVisibilityModel: {
                                grainID: false,
                                audience: false,
                                market: false,
                            },
                        },
                    }}
                    tableProps={{
                        rowHeight: 56,
                        checkboxSelection: true,
                    }}
                />
            </StyledAccordionDetails>
        </Accordion>
    );
};

export default AliasingAccordion;
