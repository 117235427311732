export const UI_FRIENDLY_DIMENSIONS = {
    account: 'Account',
    campaign_group: 'Campaign Group',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    placement: 'Placement',
    line_item: 'Line Item',
    ad: 'Ad',
    creative: 'Creative',
    keyword: 'Keyword',
};
export const ID_TO_GRAIN = {
    account_id: 'account',
    campaign_group_id: 'campaign_group',
    campaign_id: 'campaign',
    adgroup_id: 'adgroup',
    placement_id: 'placement',
    line_item_id: 'line_item',
    ad_id: 'ad',
    creative_id: 'creative',
    keyword_id: 'keyword',
};

export const GRAIN_TO_ID = Object.fromEntries(
    Object.entries(ID_TO_GRAIN).map(([key, value]) => [value, key]),
);

export const GRAIN_ORDER = [
    'account',
    'campaign_group',
    'campaign',
    'adgroup',
    'placement',
    'line_item',
    'ad',
    'creative',
    'keyword',
];
