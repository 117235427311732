import { useEffect, useState } from 'react';
import { GOODWAY_YELLOW } from '../../constants/colors';
import CellTextField from '../atoms/CellTextField';

const AliasCell = ({
    row,
    applyRowChange,
    rowIndex,
    id,
    alias,
    name,
    field,
    default_field,
    key_field,
    labelText = 'Original Name: ',
}) => {
    const [aliasText, setAliasText] = useState(alias || name);
    const [label, setLabel] = useState();
    useEffect(() => {
        setAliasText(alias || name);
        setLabel(alias && name && `${labelText} ${name}`);
    }, [alias, name]);
    return (
        <CellTextField
            value={aliasText}
            key={id}
            placeholder={name}
            label={label}
            allowSpecialCharacters={true}
            onBlur={() => {
                const isRemoved = aliasText === name || !aliasText;
                if (isRemoved) {
                    setAliasText(name);
                }

                const aliasConfig = {
                    type: 'alias',
                    config: {
                        field: field,
                        key: key_field,
                        additional_filters: [
                            {
                                field: `${row.grainType}_id`,
                                key: row.grainID,
                            },
                        ],
                        default_field,
                        alias: aliasText,
                    },
                };

                applyRowChange(row, aliasConfig, rowIndex, isRemoved);
            }}
            onChange={event => setAliasText(event.target.value)}
            style={{ width: '100%', label: { marginTop: '4px', color: GOODWAY_YELLOW } }}
        />
    );
};

export default AliasCell;
