import { gql } from '@apollo/client';

export const GET_ACTIVITIES = gql`
  query GetActivitiesByPlatformAccount($accountMaps: [AccountMapInput!]!) {
    getActivitiesByPlatformAccount(accountMaps: $accountMaps) {
      activityName
      activityID
      platform
      accountName
      accountID
    }
  }
`;
export const GET_ACTIVITIES_BY_GRAIN = gql`
query getActivitiesByGrain($grain: String!, $accountMaps: [AccountMapInput!]!) {
    getActivitiesByGrain(grain: $grain, accountMaps: $accountMaps) {
      activityName
      activityID
      platform
      accountName
      accountID
      grainType,
      grainName,
      grainID
    }
  }
`;
